<template>
  <div>
    <hb-modal
      :title="modalText[action].title"
      confirmation
      size="medium"
      v-if="dialog"
      v-model="dialog"
      @close="$emit('closeModal')"
      :footerOff="!allowUpdate"
      show-help-link
    >
      <template v-slot:content>
        <div class="px-6 py-4" v-if="isActiveProcesses">
          <template class="pt-4" v-if="!allowUpdate && isUpdate">
            You can update this document by removing it from the listed processes.
          </template>
          <template v-else>{{modalText[action].body}}</template>
          <div class="pt-4" v-if="!isUpdate">
            You can delete this document by removing it from the listed processes.
          </div>
          <div class="pt-4">
            <li v-for="item in activeProcesses">{{item}}</li>
          </div>
        </div>
        <div class="px-6 py-4" v-if="!isUpdate && !isActiveProcesses">Are you sure you want to permanently delete this document?</div>
        <div class="px-6 py-4" v-if="isUpdate && allowUpdate">Are you sure you want to update this document?</div>
      </template>
      <template v-slot:right-actions>
        <hb-btn primary @click="$emit('updateItem')" v-if="isUpdate" :disabled="isLoaded" :loading="isLoaded">Update Document</hb-btn>
        <hb-btn primary @click="$emit('deleteItem')" :disabled="isLoaded" :loading="isLoaded" v-else>Delete Document</hb-btn>
      </template>
    </hb-modal>
  </div>
</template>

<script type="text/babel">

export default {
  name: "ActiveProcessesModal",
  data() {
    return {
      modalText: {
        delete: {
          title: "Delete Document",
          body: "You cannot delete this document because it is used in at least one active process. To replace this document, upload a replacement by using the edit option."
        },
        update: {
          title: "Update Document",
          body: "This document is used in an active process. Updating any information will apply these changes to all of the following processes."
        }
      },
      isUpdate: false
    };
  },
  props: ['value', 'activeProcesses', 'action', 'isLoaded', 'allowUpdate'],
  created() {
    
    this.isUpdate = this.action == "update" ? true : false;
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    isActiveProcesses(){
      return this.activeProcesses.length ? true : false;
    }
  }
}
</script>

<style lang="scss" scoped></style>