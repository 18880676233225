<template>
  <div>
    <hb-modal :title="isEditDocument? 'Edit Document' : 'Upload Document'" show-help-link v-model="dialog">
      <template v-slot:subheader>
        Upload a file to use in your Document Library, then add additional information.
      </template>
      <template v-slot:content>
        <div>
          <hb-form
            label="Document"
            required
            large
            class="custom-doc"
            description="Upload document with .docx file extension."
          >
            <v-file-input
                accept=".docx"
                v-model="files"
                prepend-inner-icon="mdi-paperclip"
                prepend-icon=""
                v-validate="isEditDocument ? 'uploadedFileName' : 'required|uploadedFileName'"
                data-vv-scope="default"
                data-vv-as="Document"
                name="documentFile"
                hide-details
                :error="errors.collect('default.documentFile').length > 0"
                placeholder="Select Document to Upload"
            ></v-file-input>
            <!-- <div class="d-flex mt-5" style="max-width: 100%;"  v-if="getSettings.nationalAccountFeature === '1'">
              <span>Is this a National Account document?</span>
            <hb-radio-group row class="pl-3" v-model="formData.is_national_account">
          <HbRadio label="Yes" :value=true />
          <HbRadio label="No" :value=false />
        </hb-radio-group>
            </div> -->
          </hb-form>
          <hb-form label="Document Name" large required description='Document name must not contain any "." characters.' >
            <hb-text-field
              v-model="formData.name"
              v-validate="'required|max:45|fileName'"
              data-vv-scope="default"
              data-vv-as="Document Name"
              name="documentName"
              :error="errors.collect('default.documentName').length > 0"
              placeholder="Enter Document Name"
            ></hb-text-field>
          </hb-form>
          <hb-form label="Document Type" large required>
              <hb-select
                v-model="formData.type"
                :items="getUnSignedDocumentTypes"
                item-text="name"
                item-value="value"
                v-validate="'required'"
                data-vv-scope="default"
                data-vv-as="Type"
                name="documentType"
                :error="errors.collect('default.documentType').length > 0"
                placeholder="Select Document Type"
               />
          </hb-form>
          <enable-properties-field
            @selectedProperties="selectedProperties"
            :selectedPropertiesList.sync="selectedPropertiesList"
          />
        </div>
      </template>
      <template v-slot:right-actions>
        <hb-btn @click="confirmUpdate" v-if="isEditDocument">Save</hb-btn>
        <hb-btn @click="submitForm" :disabled="isLoaded" :loading="isLoaded" v-else >Upload Document</hb-btn>
      </template>
    </hb-modal>
    <active-processes-modal v-if="isActiveProcesses" v-model="isActiveProcesses" :activeProcesses="activeProcesses" action="update" @updateItem="submitForm" :isLoaded="isLoaded" :allowUpdate="allowUpdate"></active-processes-modal>
  </div>
</template>

<script type="text/babel">
import ActiveProcessesModal from './ActiveProcessesModal.vue';
import EnablePropertiesField from '../Common/EnablePropertiesField';
import {mapGetters, mapActions} from 'vuex';
import api from '../../../assets/api.js';
import { notificationMixin } from '@/mixins/notificationMixin.js';
import { EventBus } from '../../../EventBus.js';
import { Validator } from 'vee-validate';

Validator.extend('uploadedFileName', {
  getMessage: 'Uploaded file must contain only a ".docx" filetype at the end of the name and no other "." characters',
  validate: file => file.name.split('.').length === 2
})

Validator.extend('fileName', {
  getMessage: 'Document name must not contain any "." characters',
  validate: name => !name.includes('.')
})

export default {
  name: "UploadDocumentModal",
  data() {
    return {
      files: [],
      formData: {
        name: "",
        type: "",
        is_national_account: false,
        tempalte: "",
        Properties: [],
      },
      selectedPropertiesList: [],
      activeProcesses: [],
      isActiveProcesses: false,
      isLoaded: false,
      allowUpdate: false,
    };
  },
  mixins: [notificationMixin],
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Object
    }
  },
  components: {
    EnablePropertiesField,
    ActiveProcessesModal
  },
  created() {
    if(this.isEditDocument) {
      this.formData = Object.assign({}, this.selected);
      if(this.selected.Properties.length) {
        this.transformSelectedProperties();
      }
    } 
  },
  computed: {
    ...mapGetters({
      getUnSignedDocumentTypes: 'documentsStore/getUnSignedDocumentTypes',
      getSettings: 'authenticationStore/getSettings',
    }),
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    isEditDocument() {
      return (this.selected && this.selected.id) ? true : false;
    }
  },
  methods: {
    ...mapActions({
      uploadDocument: 'documentsStore/uploadDocument'
    }),
    async confirmUpdate() {
      await api.get(this, api.DOCUMENT + this.selected.id + '/active-processes?is_corporate=true' + this.findUnSelectedProperties()).then(async payload => {
        if(payload.active_processes.length) {
          this.activeProcesses = payload.active_processes;
          this.allowUpdate = payload.allow_update;
          this.isActiveProcesses = true;
        } else {
          this.submitForm();
        }
      }).catch(err => {
        this.showMessageNotification('error', err);
      });
    },
    async submitForm() {
      const status = await this.$validator.validateAll('default');
      if(!status) return;
      this.isLoaded = true;
      var fData = new FormData();
      fData.append("template", this.files);
      fData.append("name", this.formData.name);
      fData.append("type", this.formData.type);
      // fData.append("is_national_account", this.formData.is_national_account);
      // if (!this.formData.is_national_account) {
        fData.append("Properties", JSON.stringify(this.formData.Properties));
      // }
      if(this.isEditDocument) {
        this.$http.put(api.DOCUMENT + this.selected.id, fData).then(res => {
          this.isLoaded = false;
          let payload = res.body.data;
          if(payload.document_id) {
            this.isActiveProcesses = false;
            this.$emit('onDocumentsUpdated');
            this.showMessageNotification({type: 'success', description: `You made changes to ${this.selected.name}`});
          }
        }).catch(err => {
          this.showMessageNotification({description: err.body.msg});
          this.isLoaded = false;
        });
      } else {
        this.$http.post(api.DOCUMENT, fData).then(res => {
          this.isLoaded = false;
          let payload = res.body.data;
          if(payload.document_id) {
            this.$emit('onDocumentsUpdated');
            this.showMessageNotification({type: 'success', description: "You’ve uploaded a new document."});
          }
        }).catch(err => {
          this.showMessageNotification({description: err.body.msg});
          this.isLoaded = false;
        });
      }
    },
    selectedProperties(properties) {
      this.formData.Properties = 
        properties.map((property_id) => {
          return {
            id: property_id,
          };
        });
    },
    transformSelectedProperties() {
      this.selectedPropertiesList = this.selected.Properties.map(p => {
        return p.id
      });
    },
    findUnSelectedProperties() {
      let unSelectedProperties = '';
      this.selectedPropertiesList.map(item => {
        let found = false;
        this.formData.Properties.map(i => {
          if(i.id == item) {
            found = true;
          }
        });
        if(!found) 
          unSelectedProperties += `&property_ids[]=${item}`;
      });
      return unSelectedProperties;
    }
  }
};
</script>

<style lang="scss" scoped>
.custom-doc {
  ::v-deep .hb-aviary-form-input-370-max-width{
    max-width: 100% !important;

    .v-file-input{
      max-width: 370px !important;
    }
  }
}
</style>
